import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1161.000000 1162.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1162.000000) scale(0.100000,-0.100000)">


<path d="M5625 11614 c-618 -19 -1267 -146 -1837 -360 -243 -92 -310 -120
-508 -218 -509 -250 -944 -541 -1345 -900 -109 -99 -344 -330 -420 -416 -133
-149 -256 -298 -336 -405 -24 -33 -48 -64 -52 -70 -41 -51 -182 -260 -259
-386 -514 -833 -799 -1741 -858 -2736 -45 -769 81 -1602 356 -2333 106 -283
295 -680 434 -915 326 -547 744 -1050 1199 -1441 19 -16 39 -34 45 -39 6 -6
47 -40 91 -76 796 -653 1766 -1085 2780 -1239 355 -54 498 -64 890 -64 268 0
404 5 530 17 600 61 1154 197 1675 412 421 173 833 404 1205 674 94 68 297
228 352 277 18 16 64 56 102 89 90 78 397 387 486 490 39 44 78 89 88 100 150
165 473 630 622 895 77 138 257 512 319 665 553 1365 563 2931 27 4285 -91
230 -115 284 -226 505 -424 839 -1025 1541 -1795 2100 -41 29 -93 67 -115 83
-67 48 -323 205 -450 276 -722 404 -1564 656 -2395 715 -158 12 -462 19 -605
15z m1054 -1485 c565 -67 1092 -256 1571 -562 36 -23 70 -45 75 -49 6 -4 69
-51 140 -104 170 -127 231 -179 406 -354 297 -296 511 -592 700 -970 191 -381
303 -751 366 -1210 25 -181 25 -713 0 -895 -62 -459 -173 -826 -370 -1219
-292 -583 -691 -1038 -1254 -1429 -45 -31 -85 -57 -89 -57 -4 0 -21 -10 -38
-23 -52 -39 -304 -172 -436 -230 -439 -193 -884 -298 -1337 -314 l-173 -6 0
36 0 36 53 5 c28 3 109 8 179 11 448 19 966 159 1398 376 194 97 455 260 590
368 234 188 440 387 593 576 122 150 251 335 342 490 48 83 205 399 205 414 0
5 11 34 25 66 67 151 159 466 195 667 54 306 74 684 51 978 -26 321 -102 668
-213 965 -46 124 -161 382 -194 435 -12 19 -34 60 -49 90 -56 112 -248 391
-370 539 -204 248 -510 524 -776 701 -483 321 -993 511 -1592 591 -234 32
-641 32 -877 0 -514 -68 -936 -207 -1365 -449 -79 -44 -265 -161 -275 -172 -3
-3 -35 -27 -72 -53 -295 -208 -629 -549 -837 -854 -33 -48 -65 -93 -70 -99 -5
-6 -23 -36 -41 -65 -40 -69 -51 -88 -72 -120 -28 -42 -147 -285 -192 -393 -78
-185 -131 -342 -176 -526 -66 -271 -85 -400 -104 -705 l-11 -180 -33 -3 c-22
-2 -34 2 -38 13 -10 25 15 372 37 525 157 1091 794 2055 1744 2640 121 74 403
219 505 261 408 163 796 254 1200 279 180 12 489 2 679 -21z m-35 -1409 c451
-33 775 -108 1061 -246 98 -47 148 -78 249 -156 43 -33 133 -129 164 -174 14
-22 28 -41 31 -44 4 -3 25 -41 47 -86 45 -87 86 -209 106 -319 19 -106 17
-352 -5 -455 -56 -265 -194 -474 -418 -633 -71 -50 -252 -144 -336 -173 -29
-10 -53 -21 -53 -24 0 -3 19 -21 41 -40 65 -54 218 -207 300 -300 41 -47 76
-87 79 -90 3 -3 21 -25 40 -50 19 -25 38 -49 43 -55 49 -61 154 -207 189 -262
23 -37 46 -72 50 -78 25 -31 68 -102 68 -112 0 -6 3 -13 8 -15 9 -4 102 -164
102 -177 0 -5 4 -11 8 -13 23 -10 218 -441 207 -458 -3 -5 -312 -8 -688 -7
-729 3 -740 3 -841 53 -70 35 -98 71 -182 239 -99 196 -162 303 -299 510 -128
193 -260 373 -382 523 l-84 102 -211 -2 c-153 -2 -212 -6 -215 -15 -3 -7 42
-49 99 -93 57 -45 104 -85 106 -89 1 -5 -39 -46 -90 -92 -51 -46 -120 -109
-153 -139 -33 -30 -107 -98 -164 -150 -124 -113 -114 -104 -176 -159 -28 -24
-72 -65 -100 -90 -27 -25 -93 -84 -145 -131 -52 -47 -104 -94 -116 -105 -11
-11 -59 -54 -105 -96 -46 -42 -187 -169 -314 -284 -126 -115 -270 -245 -320
-289 -186 -168 -214 -193 -315 -286 -276 -254 -386 -348 -394 -340 -7 8 46 79
319 425 39 50 102 131 140 180 38 50 72 92 75 95 3 3 28 34 55 70 27 36 78
100 112 144 56 69 62 81 50 95 -8 9 -22 30 -31 48 -15 29 -16 196 -16 1881 l0
1850 33 5 c170 31 840 87 1247 106 241 11 974 12 1124 1z m-2500 -5568 c82
-28 146 -112 146 -191 0 -20 -5 -21 -75 -21 l-75 0 -13 33 c-24 56 -50 71
-122 71 -82 0 -120 -24 -120 -75 0 -43 28 -60 160 -95 202 -53 265 -112 265
-244 0 -139 -81 -223 -232 -239 -189 -20 -311 38 -358 172 -29 81 -28 83 33
91 93 11 95 10 108 -34 7 -21 25 -51 41 -67 24 -22 39 -28 93 -31 74 -5 116 8
145 46 26 32 25 72 0 100 -11 11 -53 31 -92 42 -219 63 -259 84 -298 161 -51
98 3 239 105 279 78 31 201 32 289 2z m-2063 -3 c141 -30 198 -224 98 -338
-15 -18 -53 -43 -84 -55 l-55 -23 39 -31 c38 -31 64 -66 160 -219 l51 -83 -92
0 -93 0 -94 141 c-51 77 -104 147 -116 155 -13 8 -49 18 -79 21 l-56 6 0 -162
0 -161 -75 0 -75 0 0 380 0 380 210 0 c115 0 232 -5 261 -11z m1027 -121 c28
-73 67 -171 86 -218 42 -103 141 -356 151 -388 7 -23 6 -23 -76 -20 l-83 3
-31 80 -31 80 -151 3 -150 3 -17 -48 c-10 -27 -24 -64 -31 -83 l-13 -35 -81
-3 c-44 -2 -81 -1 -81 2 0 6 205 545 246 646 18 47 34 91 34 98 0 9 25 12 89
12 l89 0 50 -132z m1794 -15 l3 -148 148 -3 147 -3 0 151 0 150 75 0 75 0 0
-380 0 -380 -75 0 -75 0 0 165 0 165 -150 0 -150 0 0 -165 0 -165 -75 0 -75 0
0 380 0 380 75 0 74 0 3 -147z m-2794 -915 l3 -128 69 0 c63 0 70 -2 70 -20 0
-19 -7 -20 -95 -20 l-95 0 0 151 c0 150 0 150 23 147 21 -3 22 -7 25 -130z
m660 -20 l3 -148 -26 0 -25 0 0 151 c0 150 0 150 23 147 22 -3 22 -6 25 -150z
m599 50 l68 -101 3 101 c3 101 3 102 27 102 l25 0 0 -150 0 -150 -23 0 c-19 0
-38 21 -93 107 l-69 106 -3 -106 c-3 -106 -3 -107 -27 -107 l-25 0 0 143 c0
79 3 147 7 150 22 22 47 1 110 -95z m653 37 c0 -36 3 -65 6 -65 3 0 34 29 69
65 54 54 70 65 97 65 l33 -1 -62 -58 c-35 -31 -63 -61 -63 -65 0 -4 20 -33 43
-64 24 -31 52 -69 62 -84 l17 -28 -30 0 c-23 0 -35 8 -57 42 -15 23 -32 47
-37 52 -5 6 -15 20 -22 31 -14 19 -15 19 -35 1 -16 -15 -21 -31 -21 -73 0 -50
-1 -53 -25 -53 l-25 0 0 150 0 150 25 0 c24 0 25 -2 25 -65z m841 36 c34 -35
37 -57 8 -64 -15 -4 -22 1 -29 19 -17 44 -111 43 -118 -2 -3 -23 16 -34 95
-54 47 -12 83 -50 83 -87 0 -14 -13 -37 -29 -54 -26 -25 -37 -29 -86 -29 -51
0 -60 3 -91 34 -39 39 -43 61 -13 69 15 4 22 -1 29 -18 16 -41 76 -58 121 -35
46 25 10 72 -66 85 -51 8 -83 35 -91 74 -5 25 -1 36 24 61 26 26 38 30 82 30
44 0 56 -4 81 -29z"/>
<path d="M5775 8002 c-37 -7 -39 -9 -43 -44 -3 -29 0 -38 12 -38 9 0 16 5 16
10 0 6 7 10 16 10 12 0 14 -5 8 -17 -49 -113 -49 -116 -53 -544 -1 -223 1
-422 4 -443 l8 -38 311 5 c346 5 407 14 531 72 123 57 191 131 240 265 26 69
28 86 28 220 0 162 -16 250 -60 332 -52 95 -152 163 -288 196 -64 15 -652 26
-730 14z"/>
<path d="M1760 2935 l0 -95 115 0 c156 0 195 21 195 102 -1 71 -36 88 -189 88
l-121 0 0 -95z"/>
<path d="M2955 2941 c-3 -16 -24 -76 -46 -133 -21 -56 -39 -104 -39 -105 0 -2
45 -3 100 -3 55 0 100 3 100 7 0 16 -95 263 -101 263 -4 0 -10 -13 -14 -29z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
